import { Box, Stack } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";

import { LoggedOutBar } from "../../components/AppBar";
import { InternalButtonLink } from "../../components/Link";
import Overview from "../../components/Overview";
import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingL } from "../../components/Typography";

interface imageProps {
	intro_image: {
		publicURL: string;
	};
}

const introImage = (): imageProps => {
	return useStaticQuery<Queries.OdhlaseniImagesQuery>(
		graphql`
			query OdhlaseniImages {
				intro_image: file(relativePath: { eq: "logout.jpg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		`
	);
};

export const AppBar = (): JSX.Element => <LoggedOutBar />;

function LogoutPage({ pageContext }): JSX.Element {
	return (
		<Stack sx={{ pt: { md: "72px" } }}>
			<HeadingL sx={{ mb: 4 }}>Byli jste bezpečně odhlášeni</HeadingL>
			<BodyTextM>
				Dlouho jste neprovedli žádnou akci, proto jsme vás raději odhlásili.
			</BodyTextM>
			<Overview
				image={introImage().intro_image.publicURL}
				alt="Přihlášení"
				sx={{
					overflow: { md: "visible", xxs: "hidden" },
					mt: { md: 5, xxs: 0 },
					mb: { md: 0, xxs: -4 },
					mx: { md: 0, xxs: -4 },
					p: { md: 0 },
					".MuiCardContent-root": {
						boxShadow: (theme) => theme.customShadows.close,
						backgroundColor: "white.main",
					},
					".MuiCardMedia-root": {
						display: { md: "none" },
						backgroundPositionY: "2px",
					},
				}}
			>
				<Box sx={{ px: 3 }}>
					<HeadingL>Přihlaste se ke svému účtu znovu</HeadingL>
					<BodyTextM component="p" marginY={6}>
						Pro správné směřování do klientského portálu si vyberte produkt, na
						který se chcete podívat.
					</BodyTextM>
					<Stack direction="row" justifyContent="space-between" spacing={5}>
						<InternalButtonLink
							to="/prihlaseni-nebo-registrace"
							variant="outlined"
							sx={{ width: "50%", py: "11px" }}
						>
							<BodyTextM>Penzijko</BodyTextM>
						</InternalButtonLink>
						<InternalButtonLink
							to="/rozcestnik-zivotko/"
							variant="outlined"
							sx={{ width: "50%", py: "11px" }}
						>
							<BodyTextM>Životko</BodyTextM>
						</InternalButtonLink>
					</Stack>
				</Box>
			</Overview>
		</Stack>
	);
}

export default LogoutPage;

export const Head = (): JSX.Element => (
	<SEO title="Odhlášení">
		<meta name="robots" content="noindex" />
	</SEO>
);
